/* this needs to take up the entire  */

/* .container {
  display: flex;
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
} */


/* For the animation at the top of the page, the lottie-animation */

#lottie-container{
  display: inline-block; 
  width: auto; 
  height: auto; 
  margin-top: 100px; 
  max-width: 300px; 
  max-height: 200px; 
  min-width: 150px; 
  min-height: 100px; 
}



.diceImageContainer {
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  padding: 10px;
}

.diceImageContainer > p {
  padding-top: 10px; 
  margin: 0; 
}


/* All of this has to do with styling my brightness range slider for different browsers */
/* General styling for the range input */

/* For Edge and other browsers that show the range value */

/* General styling for the range input */
#brightness {
  -webkit-appearance: none; /* Override default appearance */
  width: auto;
  height: 8px;
  background: #ddd;
  outline: none;
  border-radius: 5px;
  margin: 10px 0;

}

/* WebKit browsers (Chrome, Safari, Edge) */
#brightness::-webkit-slider-runnable-track {
  width: 100px;
  height: 8px;
  cursor: pointer;
  background: #ffcc00;
  border-radius: 5px;
}

#brightness::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #333;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-top: -6px; /* Centers the thumb on the track */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Added shadow */
}

/* Mozilla browsers (Firefox) */
#brightness::-moz-range-track {
  width: 100px;
  height: 8px;
  cursor: pointer;
  background: #ffcc00;
  border-radius: 5px;
}

#brightness::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Added shadow */
}

/* Internet Explorer */
#brightness::-ms-track {
  width: 100px;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

#brightness::-ms-fill-lower {
  background: #ffcc00;
  border-radius: 5px;
}

#brightness::-ms-fill-upper {
  background: #ffcc00;
  border-radius: 5px;
}

#brightness::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-top: -6px; /* Centers the thumb on the track */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Added shadow */
}

/* Hide the default value tooltip in Edge and other browsers */
input[type="range"]::-ms-tooltip {
  display: none;
}

/* This is for the trim container, input boxes etc. */ 
#trimDivContainer{
  display: flex; 
  flex-direction: row; 
}

#trimDivContainer > input{
  padding: 5px;
  margin: 0px 0;
  border-radius: 5px;
  width: 40px;
  background-color: #ffcc00;
  outline: none; 
  border: none; 
  margin-right: 10px; 
}

#trimDivContainer > label{
  margin-left: 10px; 
}
.trimAlignment {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
}

.trimAlignment label {
  margin-right: 0.5rem; /* Space between label and input */
  /* You can adjust this spacing as needed */
}


#trim-bottom {
  width: 100%;
}


/* #trimDivContainer > .trimAlignment {
  margin-bottom: 10px; 
} */

/* #trimDivContainer label {
  margin-right: 10px; 
} */

/* #trimDivContainer input {
  flex: 1; 
} */











 .images-container {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  justify-content: center; /* Center the items within the container */
  gap: 2rem; /* Adjust the gap between items (1rem is an example) */
}

 /** Website Title **/
 .slogan > strong {
  color: black; 
  font-size: 25px; 
 }


 #theProcess {
  margin: 0px;
  /* margin-top: 200px; */
  font-size: 100px;
  color: #ffffff; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 6px 4px rgb(0, 0, 0); 
  ; 
 }

 #carouselTitle {
  margin: 0px; 
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white; 
}

/* Media query for tablets and smaller screens */
@media (max-width: 900px) {
  #carouselTitle {
    font-size: 70px; /* Reduce font size for medium-sized screens */
    text-shadow: 4px 4px 3px white; /* Adjust text shadow for smaller size */
  }
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  #carouselTitle {
    font-size: 50px; /* Further reduce font size for mobile devices */
    text-shadow: 3px 3px 2px white; /* Adjust text shadow for mobile */
  }
}


#title {
  margin: 5px;
  margin-top: 200px;
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white; 
}

.item{
  display: inline-block;
  width: 100%
}



#featuredMosaics{
  margin: 5px;
  margin-top: 200px;
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white;   
}

@media (max-width: 600px) {
  #title {
    font-size: 80px; /* Smaller font size for medium screens */
  }
}

@media (max-width: 500px) {
  #title {
    font-size: 60px; /* Even smaller font size for smaller screens */
  }
}

@media (max-width: 400px) {
  #title {
    font-size: 50px; /* Smallest font size for very small screens */
  }
}







.imageDiv img{
  max-width: 200px; /* 50% of the viewport width */
  height: auto; /* Automatically adjusts height to maintain aspect ratio */
}

#diceSizeSetter{
  width: 200px;
  margin: 0px; 
  padding: 0px;
  font-family: 'Arial', sans-serif; 
  color: #1a1a1a;
  background-color: #ffcc00;
  border: 2px solid #ffcc00;
  border-radius: 8px;
  text-align: center;
}

#diceSizeSetterContainer{
  margin: 30px;
  padding-bottom: 20px;
  /* margin-bottom: 100px;  */
}




.s::placeholder {
  color: #666;
}

.styled-input:focus {
  outline: none;
  border-color: #ffaa00; 
  box-shadow: 0 0 12px rgba(255, 170, 0, 0.8);
}



/** --------------------------------------------------- Image Uploader controls --------------------------------------------------- */

/* #imageControls {
  text-align: center;
  display: flex; 
  justify-content: center;
  flex-direction: column;
  align-items: center; Ensures child elements stretch to fill the width
} */

#imageControls > div {
  padding: 0px; 
  /* width: 400px;  */
  display: flex; 
  flex-direction: row;
  /* align-items: stretch; Ensures child elements stretch to fill the width */
  gap: 10px; /* Adjust the gap between each div */
}

#imageControls > div > * {
  margin: 0; /* Remove default margins for elements */
  width: 100%; /* Ensures elements stretch to fill the width of their container */
}



/* This is for the increase and decrease dice size buttons */
#imageControls button {
  background-color: #ffcc00;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 5px;
  cursor: pointer;
}



#imageControls button:hover {
  background-color: #d2a802;
}

#imageControls label {
  display: block;
  margin: 10px 0;
  
}

#imageControls input[type="number"] {
  padding: 5px;
  margin: 0px 0;
  border-radius: 5px;
  width: 40px;
  background-color: #ffcc00;
  outline: none; 
  border: none; 
  /* border: 2px solid black;  */
}

/* #imageControls input[type="number"]:focus {
  padding: 5px;
  margin: 0px 0;
  border-radius: 5px;
  width: 50px;
  background-color: #ffcc00;
  border: #000000; 
} */

.radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.radio-group input[type="radio"] {
  display: none;
}

.radio-group label {
  background-color: #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.radio-group input[type="radio"]:checked + label {
  background-color: #4CAF50;
  color: white;
}

.radio-group label:hover {
  background-color: #ccc;
}

#imageControls p {
  margin: 10px 0;
  font-size: 1.2em;
  font-weight: bold;
}



#diceSizeSetter::placeholder {
  color: #666;
}


#diceSizeSetter:focus {
  outline: none;
  /* box-shadow: 0 0 12px rgba(255, 170, 0, 0.8); */
}


/** For the new radio buttons image: */ 


/* Hide the default radio button */
.radio-input {
  display: none;
}

/* Style the label to display your custom images */
.radio-label {
  cursor: pointer;
  display: inline-block;
  margin: 10px;
}

/* Style for the images */
.radio-image {
  width: 50px;  /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  transition: transform 0.2s;
  border-radius: 8px; /* Optional: to give dice a rounded look */
}

/* Change the image appearance when the radio button is checked */
.radio-input:checked + .radio-label .radio-image {
  transform: scale(1.2); /* Example effect */
  border: 2px solid #ffaa00; /* Example border color */
}



/** --------------------------------------------------- Upload Image Button --------------------------------------------------- */

#imageInput{
  width: 100%; 
}

#generator > span {
  width: 500px; 
}

#generator > input {
  width: 500px; 
}


/* Styles for the upload button container */
.uploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

/* Styles for the label acting as the button */
.imageInputButton {
  display: inline-block;
  padding: 20px 40px;
  margin: 20px; 
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: #333;
  border: 2px solid #555;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.imageInputButton:hover {
  background-color: #555;
  border-color: #777;
}

/* Hide the actual file input */
#imageInput {
  display: none;
}

/* Dice-themed styles */
.uploadContainer {
  background-color: #282c34; /* Dark background */
}

.imageInputButton {
  background-color: #2a2a2a;
  border: 2px solid #fff;
  color: #fff;
}

.imageInputButton:hover {
  background-color: #1a1a1a;
  color: #ffcc00; /* Dice-themed yellow */
  border-color: #ffcc00; /* Dice-themed yellow */
}


/* For generating a png - The PNG generate button */

#generatePNG{
  margin: 10px;
  border-radius: 10px; 
}

#mosaicSummaryDiv {
margin-top: 30px; 
width: 80%; 
max-width: 700px; 
/* background-color: rgb(163, 163, 163); */
display: inline-block; 
padding: 10px;
font-size: 20px; 
}

#mosaicSummaryDiv p {
  text-align: left; 
  width: 100%; 
  border-bottom: 1px solid black; 
  display: flex; 
}

#mosaicSummaryDiv p > div {
  font-weight: bold; 
}

#shopLink{
  border: none; 
  text-decoration: none;
  color: black; 
  text-shadow: 2px 2px 2px #FFCC02;
  margin-left: 5px; 
}

#blogLink{
  border: none; 
  text-decoration: none;
  color: black; 
  text-shadow: 2px 2px 2px #FFCC02;
  margin-left: 5px; 

}



#mosaicSummaryDiv input {
  border: none; 
  background-color: #FFCC02;
  border-radius: 5px; 
  width: 60px; 
  outline: none;
  margin-left: 5px; 
  font-size: 20px; 
}

#mosaicSummaryDiv div {
  text-align: left;
}

#mosaicSummaryDiv > p > strong {
  margin-left: auto; 
}


#mosaicSummaryDiv img {
  border: 2px solid #FFCC02; 
  border-radius: 2px; 
}

#mosaicShop{
  background-color: rgb(100, 100, 100);
  margin: 0px;
  padding-bottom: 60px;

}

.mosaic-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

#whatsNextListItems{
  display: inline-block; 
}

#whatsNextListItems li{
  text-align: left;
  font-size: 25px; 
  color: #555;

}

#whatsNextListItems li::marker{
  color: #ffcc00; 
}

#nowWhat {
  text-align: left;
  margin-left: 30px;
}


.mosaic-heading {
  font-size: 2.5rem;
  color: #ffcc00;
  margin-bottom: 20px;
}

#mosaic-description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 15px;
  padding: 0 20px;
}


#toggle-controls{
  border-radius: 10px; 
  margin: 5px; 
}



/* -------------------------------------------------- styling -------------------------------------------------- */

/* Navbar container */
.navbar {
  position: fixed;
  top: 0;
  left: 0; /* Ensure it starts at the left edge */
  width: 100%;
  /* background-color: #333; */
  color: black;
  padding: 10px 0; /* Adjust padding as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: top 0.3s;
  z-index: 1000;
  box-sizing: border-box; /* Ensure padding is included in the width */
  overflow: hidden; /* Prevents overflow issues */
}

/* Navbar logo */
.navbar-logo {
  width: 50px;
  height: 50px;
  margin-left: 10px; /* Adjust margin as needed */
}

/* Navbar links container */
.nav-links {
  display: flex;
  gap: 15px;
  /* margin-right: 10px; Adjust margin as needed */
  margin-right: 10px; 

}

/* Navbar links */
.nav-links a {
  font-size: 20px; 
  font-weight: bold; 
  color: black;
  text-decoration: none;
  padding: 8px 12px;
  /* border-radius: 4px; */
  transition: background-color 0.3s;
}

#background{
  width: 100%; 
  height: 200vh; 
  top: 0px; 
  left: 0px; 
  position: absolute; 
  z-index: -100;

}

/* Navbar links hover and active states */
.nav-links a:hover {

  border-bottom: 2px solid #000000;
}

/* .nav-links a.active {
  border-bottom: 2px solid #ffCC02;

} */

/* Burger menu */
.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

/* Burger menu bars */
.burger-bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s;
}

/* Hide and show effects */
.navbar-hide {
  top: -80px; /* Adjust based on your navbar height */
}

.navbar-show {
  top: 0;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .nav-links.nav-links-mobile {
    display: flex;
  }

  .burger-menu {
    display: flex;
    margin-right: 10px; /* Adjust margin as needed */
  }
}


html {
  scroll-behavior: smooth;
}

#logoAndNameNavbar{
  display: flex; 
  flex-direction: vertical; 
}

#logoAndNameNavbar > p {
  margin-left: 10px; 
  font-weight: bold;
}

/** --------------------------------------------------- mosaicContainerStyling --------------------------------------------------- */

#diceMosaicContainer{
height:  auto; 
/* overflow-x: auto;
width: 2000px; */ 
margin-left: 200px; 
margin: 0px auto; 
padding: 0px; 

} 


#generatorContainer{
  /* padding-top: 20px;  */
  color: black; 
  background-color: rgb(255, 255, 255);
  overflow-x: auto;
  /* padding-top: 200px;  */
}

/** --------------------------------------------------- dice falling effect --------------------------------------------------- */

@keyframes fall {
  to {
    transform: translateY(calc(100vh - 50px)); /* Just handle the falling effect here */
    opacity: 0; /* Fade out */
  }
}

.falling-dice {
  animation: fall 6s ease-in forwards; /* Use only the fall animation here */
}



/** --------------------------------------------------- mainParagraph and hoome text styling --------------------------------------------------- */


#mainParagraph {
  width: 600px; 
  display: inline-block; 

}



#homePageText {
  font-size: 15px;
}

#homeParagraph {
  
  font-family: 'Arial', sans-serif; /* Choose a clean, modern font */
  font-size: 1.2rem; /* Increase font size for better readability */
  line-height: 1.6; /* Add space between lines for clarity */
  color: #333; /* Dark gray color for good contrast */
  /* background-color: #f9f9f9; Light background for a subtle highlight */
  padding: 20px; /* Add some padding around the text */
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a soft shadow for depth */
  margin: 20px 0; /* Margin above and below the paragraph */
  margin-top: 150px; 
  text-align: center; /* Center-align the text */
  width: 50%; 
  display: inline-block; 
}

#homeParagraph strong {
  color: #007bff; /* Accent color for strong text */
}

/* #particles{
  width: 100%; 
  height: 100vh; 
} */

/** --------------------------------------------------- About section styling --------------------------------------------------- */


/* styling.css */

/* Styling for the overall About component */

#aboutBackground{
  /* padding: 100px;  */
  padding-top: 10px;
  background-color: rgb(178, 178, 178);;
}


#About {
  padding: 20px; /* Adjust padding as needed */
  width: 100%; 
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  background-color: rgb(109, 109, 109);
}



/* For the search container */
/* src/styling.css */

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.search-container input[type="text"] {
  padding: 10px 15px;
  border: 2px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  font-size: 16px;
  width: 300px;
  transition: border-color 0.3s ease;
}

.search-container input[type="text"]:focus {
  border-color: #007bff;
}

.search-container button {
  padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 0 4px 4px 0;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.search-container button:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}


#aboutDivs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

/* Media query to change to column layout on smaller screens */
@media (max-width: 768px) { /* Adjust the max-width to your preference */
  #aboutDivs {
    flex-direction: column;
    justify-content: center; /* Align items vertically when in column mode */
  }

}



#Features .mainText {
  font-size: 25px;
  font-weight: bold;
  color: #ffcc00;
  text-shadow:
    -1px -1px 0 black,  /* Top-left shadow */
    1px -1px 0 black,   /* Top-right shadow */
    -1px 1px 0 black,   /* Bottom-left shadow */
    1px 1px 0 black;    /* Bottom-right shadow */  width: 80%; /* Default width for larger screens */
  margin: 0 auto; /* Center the text */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  #Features .mainText {
    width: 100%; /* Full width for smaller screens */
  }
}

#CreatingTheArt .mainText {
  font-size: 20px;
  font-weight: bold; 
  color: #ffcc00; 

}

/* Styling for the Features section */
#Features {
  /* background-color: #f2f2f2; */
  padding-bottom: 30px; /* Space between sections */
  border-bottom: 1px solid black;
  width: 60%;
  /* border-radius: 20px; */
}

#Features h1 {
  font-size: 100px; /* Larger font size for main headings */
  color: black; /* Dark text color */
  border-bottom: 1px solid black; /* Line under heading */
  padding-bottom: 8px; /* Space below heading */
  margin-bottom: 16px; /* Space below heading */
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
}

@media (max-width: 600px) {
  #Features h1 {
    font-size: 80px; /* Smaller font size for medium screens */
  }
}

@media (max-width: 500px) {
  #Features h1 {
    font-size: 70px; /* Even smaller font size for smaller screens */
  }
}

@media (max-width: 400px) {
  #Features h1 {
    font-size: 60px; /* Smallest font size for very small screens */
  }
}




#Features h3 {
  font-size: 30px; /* Larger font size for subheadings */
  color: black; /* Slightly darker text color */
  margin-top: 14px; /* Space above each subheading */
  /* font-family: 'Georgia', sans-serif; Example of setting a font-family */
}

#Features h3:hover {
  transform: scale(1.2); /* Scale up by 10% on hover */
}

#Features p {
  font-size: 16px; /* Regular font size for paragraphs */
  color: rgb(225, 225, 225); /* Gray text color */
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 16px; /* Space below each paragraph */
}

#Features > div{
  padding: 10px 0px; 
}



/* Styling for the CreatingTheArt section */
#CreatingTheArt {
  background-color: #e0e0e0; /* Lighter gray background */
  padding: 20px;
  border-bottom: 2px solid #ccc; /* Line below section */
  border-radius: 20px;

}

#CreatingTheArt h1 {
  font-size: 28px; /* Larger font size for main headings */
  color: #333; /* Dark text color */
  border-bottom: 1px solid #999; /* Line under heading */
  padding-bottom: 8px; /* Space below heading */
  margin-bottom: 16px; /* Space below heading */
  font-family: 'Arial', sans-serif; /* Example of setting a font-family */
}

#CreatingTheArt p {
  font-size: 16px; /* Regular font size for paragraphs */
  color: #666; /* Gray text color */
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 16px; /* Space below each paragraph */
}


/* styling for accordian faq*/

.accordion-content > p{
  max-width: 500px; 
  display: inline-block; 
}


#faq > h2{
  margin: 0px; 
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white; 
}


/* Media query for tablets and smaller screens */
@media (max-width: 900px) {
  #faq > h2 {
    font-size: 70px; /* Reduce font size for medium-sized screens */
    text-shadow: 4px 4px 3px #FFAA02; /* Adjust text shadow for smaller size */
  }
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  #faq > h2 {
    font-size: 50px; /* Further reduce font size for mobile devices */
    text-shadow: 3px 3px 2px #FFAA02; /* Adjust text shadow for mobile */
  }
}







#faq {
  background-color: none;
  width: auto; 
}


.accordion {
  margin: 0 auto; /* Center the accordion horizontally */
  width: 100%; /* Make the accordion take the full width of its container */
  max-width: 800px; /* Limit the width for larger screens */
  background-color: transparent; /* Keep the background transparent */
  box-sizing: border-box; /* Include padding and borders in the width */
  padding: 20px; /* Add padding for spacing */
}

/* Media query for smaller screens */
@media (max-width: 900px) {
  .accordion {
    padding: 15px; /* Reduce padding for medium screens */
    max-width: 90%; /* Reduce the accordion width to fit smaller screens */
  }
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .accordion {
    padding: 10px; /* Further reduce padding for mobile screens */
    max-width: 60%; /* Make it take the full width on small screens */
  }
}


.accordion-item {
  /* border-bottom: 1px solid #ccc; */
  border-radius: 4px;
  margin: 0px; 
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-title {
  width: 100%;
  padding: 15px;
  text-align: left;
  /* background-color: #ffffff; */
  border-bottom: 3px solid black;
  cursor: pointer;
  outline: none;
  font-size: 20px;
}

.accordion-content {
  padding: 10px;
  background-color: #313131;
}







/** -------------------------------- Donation link -------------------------------- */


/* styling.css */

/* styling.css */

/* Styling for the support message and link */
#support-message {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

#support-message a {
  display: inline-block;
  padding: 3px;
  background-color: #ffcc00;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

#support-message a:hover {
  background-color: #e6b800;
}

#donationDiv{
  padding: 100px; 
  background-color: rgb(52, 52, 52);
}



/** -------------------------------- preset images -------------------------------- */
.preset-images {
  display: flex; /* Use flexbox to allow wrapping and spacing */
  flex-wrap: wrap; /* Ensure items can wrap to the next line */
  gap: 20px; /* Apply the desired gap between images */
  justify-content: center;
  align-items: center;
  margin: 20px auto; /* Center horizontally using margin auto */
  width: 100%; /* Set the container to be responsive to screen width */
  max-width: 700px; /* Limit the maximum width for larger screens */
  height: 100%; /* Ensure the container takes full height of parent */
  box-sizing: border-box; /* Ensure padding and borders are included in width */
  position: relative; /* Allow the container to be positioned within the parent */
  margin-bottom: 0px;
}

/* Media query for smaller screens */
@media (max-width: 900px) {
  .preset-images {
    width: 90%; /* Reduce the container width on smaller screens */
  }
  .preset-images img {
    width: calc(33.33% - 20px); /* Ensure three images per row */
  }
}

/* Media query for very small screens, like mobile */
@media (max-width: 600px) {
  .preset-images img {
    width: calc(50% - 20px); /* Two images per row for small screens */
  }
}


#contact a {
  color: #ffcc00; 
}

.preset-images > div {
  flex: 1 1 calc(16.666% - 20px); /* 6 items per row with gap taken into account */
  box-sizing: border-box; /* Ensure padding and border do not affect width */
}

@media (max-width: 768px) { /* Adjust this breakpoint as needed */
  .preset-images > div {
    flex: 1 1 calc(33.333% - 20px); /* 3 items per row for smaller screens */
  }
}

@media (max-width: 480px) { /* Adjust this breakpoint as needed */
  .preset-images > div {
    flex: 1 1 100%; /* 1 item per row for very small screens */
  }
}


.preset-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 5px; 
}

.preset-thumbnail:hover {
  transform: scale(1.1);
}


/** -------------------------------- shop  -------------------------------- */

#shop {
  width: 70%; 
  display: inline-block; 
  margin-bottom: 100px; 
  color: black; 
}

#shop.active {
  border-bottom: #ffCC02
}

#shopTitle {
  border-bottom: 1px solid white;
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  font-size: 100px; 
  color: black; 
}

/* --------------------------------------- Shop Affiliate Links ----------------------------------- */

#shop > div {
  width: 100%; 
  display: flex; 
  height: auto; 
  align-items: center; /* Center the items vertically */
  margin-top: 50px; 
}

#shop > div > img {
  width: 200px; 
  height: 100%; 
  border-radius: 5px; 
}

#shop > div > div {
  margin-left: 50px; 
  margin-right: 50px; 
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
}

#shop > div > div > p {
  margin: 0; /* Remove default margin from paragraphs */
  font-size: 30px; 
}

#shop > div > a {
  width: 100%; 
  height: 300px; 
  font-size: 20px; 
}






/** -------------------------------- data-aos manipulation  -------------------------------- */

.aos-animate[data-aos="fade-right"] {
  transform: translate3d(100px, 0, 0); /* Adjust the value as needed */
}

.aos-init[data-aos="fade-right"] {
  opacity: 0;
  transform: translate3d(-300px, 0, 0); /* Initial position, adjust the value as needed */
}

.aos-animate[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0); /* Adjust the value as needed */
}

.aos-init[data-aos="fade-left"] {
  opacity: 0;
  transform: translate3d(300px, 0, 0); /* Initial position, adjust the value as needed */
}


/* --------------------------- Carousal wheel -------------------------- */ 


@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';





#ideaContainer{
  background-color: rgb(100, 100, 100);
  margin-top: none; 
}

#ideaContainerFM{
  background-color: rgb(178, 178, 178);
  margin-top: none; 
  padding-bottom: 60px; 
  height: auto; 
}


/* ScrollWheel.css */
.scroll-wheel {
  display: inline-block; /* Keep items in a single line */
  animation: scroll-right 22s linear infinite; /* Adjust speed here */
} 

.scroll-wheel-2 {
  display: inline-block; /* Keep items in a single line */
  animation: scroll-right-2 15s linear infinite; /* Adjust speed here */
} 

/* .scroll-wheel.reverse {
  animation: scroll-reverse 25s linear infinite; 
} */



.slick-slider{
  height: 100%; 
  /* padding: 10%;  */
  padding-top: 20px; 
}

.scroll-wheel-item {
  text-align: center;
  display: flex; 
  flex-direction: horizontal; 
}

.scroll-wheel-item img {
  display: inline-block; 
  width: 100%;
  max-width: 250px;
  height: 250px; 
  border-radius: 5px; 
}

.scroll-wheel-item-FM {
  display: inline-block; /* Keep items inline */
  height: 300px; /* Set a fixed height */
  margin: 0 2px; /* Add margin to vary thickness */
  /* height: 90%;  */
}

.scroll-wheel-item-FM img {
  height: 100%; /* Ensure the image fits the container */
  width: auto; /* Maintain aspect ratio */
}

@keyframes scroll-right {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* Adjust based on the number of images */
  }
}

@keyframes scroll-right-2 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30%); /* Adjust based on the number of images */
  }
}

.scroll-wheel-item > h3 > a {
  display: block; /* Makes the anchor tag occupy the full width of its container */
  text-decoration: none; /* Removes the default underline from links */
  color: #000; /* Sets the text color to black */
  /* background-color: #f5f5f5; Sets a light grey background color */
  padding: 10px 15px; /* Adds padding inside the link for better spacing */
  border-radius: 5px; /* Rounds the corners of the link */
  transition: background-color 0.3s, color 0.3s; /* Adds a smooth transition effect for hover states */
  
}

.scroll-wheel-item > h3 >  a:hover {
  background-color: #ddd; /* Changes the background color on hover */
  color: #333; /* Changes the text color on hover */
}

.scroll-wheel-item > h3 > a:focus {
  outline: 2px solid #0056b3; /* Adds a border outline when the link is focused (for accessibility) */
  outline-offset: 2px; /* Adds space between the border and the link text */
}


.scroll-wheel-item h3 {
  margin-top: 10px;
  font-size: 1.5rem;
  color: #333;
  width: 80%; 
  display: inline-block; 
}

#scrollWheelContainer{
  overflow: hidden; /* Hide the overflow */
  white-space: nowrap; /* Prevent line breaks */}


/* --------------------------- HowTo -------------------------- */ 


.costBlur{
  filter: blur(4px);
}

#parent-how-to-container {
  background-color: rgb(55, 55, 55);
  padding: 40px; 

  /* margin: 100px 0px;  */
}

/* for the title */
#parent-how-to-container > h1 {
  margin: 0px; 
  font-size: 100px; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
}

.how-to-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  width: 100%;
  height: auto; 
  /* padding: 20px; Padding around the container */
  /* box-sizing: border-box; */
}

.item {
  text-align: center;
  width: 60%; 
}

/* video titles */
.item > h2 {
  color: #ffcc00;
}

.titleHowTo{
  transition: opacity 0.3s ease-in-out;

}



video {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: Add some styling to the videos */
  transition: opacity 0.3s ease-in-out;

}

.video.playing {
  opacity: 1;
  filter: none;
}

.video.greyed-out {
  opacity: 0.3;
  filter: grayscale(100%);
}

/* --------------------------- Generator Controls -------------------------- 
Note! There may be some underlying things here that are being interfered by in the above script!
*/ 

#chosenPhoto{
  width: 200px; 
  height: auto; 
}

/* Sidebar container */
#sidebar {
  position: absolute; /* Position absolute to keep it inside its parent */
  width: 200px; /* Fixed width for the sidebar */
  height: 600px; /* Full height of the parent container */
  background-color: #333;
  color: white;
  padding: 20px;
  transform: translateX(-120%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 999; /* Ensure it stays above other content */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 1%; 
  display: flex; 

}

/* When the sidebar is open */
#sidebar.open {
  transform: translateX(0);
}

/* Other styles remain the same */
#imageControls {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


#imageControls div {
  display: flex;
  flex-direction: column;
  width: auto; 
  gap: 10px;
}

#sidebar > div > #diceColourTypes {
  display: flex; 
  flex-direction: row; 
}

#sidebar > div > .trimAlignment {
  display: flex; 
  flex-direction: row;
}

#sidebar > div > .trimAlignment > input {
  width: 100px; 
}

.radio-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.radio-input {
  display: none;
}

.radio-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 2px solid transparent;
}

.radio-input:checked + .radio-label .radio-image {
  border-color: #ffcc00;
}

/* #trimDivContainer {
  display: flex;
  flex-direction: row;
} */

label {
  font-size: 14px;
}

button {
  background-color: #ffcc00;
  border: none; 
  padding: 10px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #ffaa00;
}


/* --------------------------- Product List styling -------------------------- */ 
#bringToLife{
  margin: 0px;
  /* margin-top: 200px; */
  font-size: 100px;
  padding-top: 100px; 
  color: #ffcc00; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 6px 4px rgb(0, 0, 0); 
}

.product-list {
  color: black; 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Dynamic columns */
  gap: 10px; /* Set a smaller gap or remove it if not needed */
  margin-left: 5%; /* Remove left and right margin */
  margin-right: 5%; 
  margin-top: 100px; 
}

.productImages{
  height: 300px; 
}

@media (min-width: 960px) {
  .product-list {
    grid-template-columns: repeat(3, 1fr); /* Maximum 3 columns */
  }
}

.product-item {
  margin: 0px; /* Remove margin to reduce space */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: rgb(230, 230, 230);
  height: 620px; 
  transition: transform 0.5s ease; 
  width: 95%; /* Use full width of the grid cell */
}

.product-item:hover {
  transform: scale(1.02)
}


.how-to-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* height: 100%;  */
  /* max-width: 200px; */
}

.product-content { 
  display: flex; /* Make it a flex container */
  flex-direction: column; /* Stack children vertically */
  height: 100%; /* Maintain full height of the parent */
}

.product-variants {
  margin-top: auto; /* Push to bottom of .product-content */
  display: flex; 
  flex-direction: column; /* Ensure variants stack vertically */
}


.product-description {
  max-height: 200px; /* Set an appropriate height */
  text-overflow: ellipsis; /* Optional: Add ellipsis for overflow text */
  text-align: left;
  overflow-y: scroll;

}



.addToCartButton{
  width: 100%; 
  margin-top: 20px; 
  border-radius: 10px 
}

.product-variants input {
  padding: 2px; /* Adds space inside the input field for a comfortable feel */
  border: 2px solid #ddd; /* Light border for a clean look */
  border-radius: 4px; /* Rounded corners for a softer appearance */
  font-size: 16px; /* Adequate font size for readability */
  width: 100%; /* Ensures the input takes full width of its container */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  margin-top: 5px; /* Adds space above the input */
  transition: border-color 0.3s ease; /* Smooth transition for border color on focus */
}

.product-variants select {
  padding: 2px; /* Adds space inside the input field for a comfortable feel */
  border: 2px solid #ddd; /* Light border for a clean look */
  border-radius: 4px; /* Rounded corners for a softer appearance */
  font-size: 16px; /* Adequate font size for readability */
  width: 100%; /* Ensures the input takes full width of its container */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  margin-top: 5px; /* Adds space above the input */
  transition: border-color 0.3s ease; /* Smooth transition for border color on focus */
}


.product-variants input:focus {
  border-color: #ffd000; /* Changes border color when the input is focused */
  outline: none; /* Removes the default outline to rely on the border color */
  box-shadow: 0 0 5px rgba(216, 116, 9, 0.5); /* Adds a subtle glow effect on focus */
}

.product-variants input::placeholder {
  color: #999; /* Lighter color for the placeholder text */
  font-style: italic; /* Makes the placeholder text italic for differentiation */
}

.product-variants select:focus {
  border-color: #ffd000; /* Changes border color when the input is focused */
  outline: none; /* Removes the default outline to rely on the border color */
  box-shadow: 0 0 5px rgba(216, 116, 9, 0.5); /* Adds a subtle glow effect on focus */
}



.product-variant button {
  margin-top: 5px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-variant button:hover {
  background-color: #0056b3;
}

.product-item > img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
}


/* Carousel for images in each product */ 

/* .carousel-container {
  position: relative;
  width: 200px; 
  height: 200px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

img {
  transition: opacity 0.5s ease;
}

 */

/* --------------------------- Shopping Cart styling -------------------------- */ 

/* src/components/Cart.css */
.cart-container {
  padding: 20px;
  max-width: 800px;
  margin: 50px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: black; 
}

.cart-items {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.cart-item span {
  flex: 1;
}

.cart-item button {
  padding: 5px 10px;
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cart-item button:hover {
  background-color: #ff4c4c;
}

.cart-summary {
  text-align: right;
}

.cart-summary h3 {
  margin: 10px 0;
  font-weight: normal;
}

.checkout-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checkout-button:hover {
  background-color: #45a049;
}


/* For the cart icon */ 

/* src/styling.css */
.cart-icon {
  position: fixed;
  top: 60px;
  right: 20px;
  background-color: rgb(255, 255, 255);
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.cart-icon .shopping-cart-icon {
  font-size: 24px;
  width: 20px; 
  height: 20px; 
}

.cart-icon .item-count {
  margin-left: 2px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 3px 7px;
  font-size: 12px;
  text-decoration: none; 
}


/* Shipping info*/ 


.shipping-section {
  margin-top: 20px;
}

.shipping-description {
  margin-top: 5px;
  font-size: 0.9em;
}

#country-select {
  margin-top: 10px;
  padding: 5px;
  font-size: 1em;
  color: grey;
}

.shipping-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  color: grey;
}

.shipping-description{
  color: grey; 
}

.shipping-table th, 
.shipping-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.shipping-table th {
  background-color: #f8f8f8;
}

.shipping-table td {
  font-size: 0.9em;
}



/* ------------------------- featured mosaics ------------------------- */ 


/* styling all blogs ------------------------------------ */

/* blog list styling */ 

.blog-list {
  width: 20%;
  /* background-color: #; */
  /* border-right: 5px solid #ddd; */
  color: black; 
  margin: 0px auto;
  /* margin-top: 0px; 
  margin-right: 0px;  */
  height: 550px; 
  margin-top: 150px; 
  margin-left: 50px; 
  overflow-y: scroll;
  position: fixed; 
  top: 50px;
  background-color: white;
}



.blog-list h3 {
  font-family: 'Arial', sans-serif;
  font-size: 40px;
  color: #343a40;
  /* margin-bottom: 20px; */
}

.blog-list ul {
  list-style-type: none;
  padding: 0;
  
}

.blog-item {
  padding: 18px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
  
}

.blog-item-link {
  text-decoration: none;
  color:rgb(52, 52, 52); 
}

.blog-item:hover {
  background-color: #f1f1f1;
}



.selected {
  background-color: #ffeaa7; /* Distinct background for the selected blog */
  font-weight: bold; /* Make the selected title bold */
}

.paragraphTitle {
  /* background-color: yellow; */
  font-size: 1.5rem;          /* Adjust font size as needed */
  font-weight: 600;           /* Bold but not too bold */
  color: #555;                /* A softer gray color */
  text-align: left;           /* Align text to the left (can change to center or right if needed) */
  padding: 0.5rem 0;          /* Padding above and below for space */
  border-left: 4px solid #00A1D6; /* A colored vertical border for emphasis */
  padding-left: 1rem;         /* Space between text and border */
  /* line-height: 1.9;           Good line spacing for readability */
  margin-bottom: 30px; 
  /* text-align: left; */
}

.open-paragraph-style {
  font-style: italic; /* You can customize the style */
  color: #333;        /* Example: dark text */
  border: none; 
}

/* .open-paragraph-style img{
  width: 10%; 
  height: auto; 

} */


/* blog display styling ----------------------------- */

/* style the blog */

.highlight {
  background-color: yellow;
}

.matchingBlogText {
  background-color: yellow; /* Highlight background color */
  font-weight: bold; /* Optional: make the highlighted text bold */
}

.blog-image {
  max-width: 100%; /* Make sure images are responsive */
  height: auto;
  display: block; /* Center images in the paragraph */
  margin: 10px 0; /* Add some space around images */
}



.blog-display {
  width: 60%;
  height: auto; /* Fixed height */
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  margin-right: 50px;
  margin-left: 25%; 
  margin-top: 100px;
  position: relative; 
  /* box-shadow:  0px 4px 20px rgba(0, 0, 0, 0.2); */
  /* overflow-y: scroll; Always show scrollbar */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */
}

.blog-display::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.blog-display::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 6px; /* Round the corners of the scrollbar */
}

.blog-display::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

/* the actual outer title */ 
#blogTitle{
  font-size: 50px; 
  width: 100%; 
  margin-left: 20px; 
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  color: black; 
}

.blog-title {
  font-family: 'Georgia', serif;
  font-size: 55px;
  color: #212529;
  /* margin-bottom: 10px; */
}

.blog-subtitle {
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  color: #495057;
  margin-bottom: 20px;
}

.blog-paragraph {
  font-family: 'Verdana', sans-serif;
  font-size: 20px;
  color: #343a40;
  line-height: 1.6;
  text-align: left;
  width: 80%; 
  display: inline-block;
  /* border: 1px solid black ; */
  padding: 20px; 
}

.blog-paragraph > img{
  width: 100%; 
  height: auto; 
  max-height: 500px; 
  /* margin-bottom: 100px;  */
  /* float: right;  */
  /* position: sticky;  */
}

.blog-placeholder {
  font-family: 'Arial', sans-serif;
  color: #888;
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}


/* blog container ----------------- */

.blog-container {
  display: flex;
  /* margin-top: 100px; */
  padding-top: 60px; 
  flex-direction: column;
  height: auto;
  width: auto;
  background-color: white;
  /* position: fixed;  */
}

.search-bar {
  /* margin-bottom: 20px; */
  padding-top: 10px;
  padding-bottom: 10px; 
  /* background-color: #8a1d1d; */
  border-bottom: 5px solid rgb(241, 241, 241);
  width: 20%; 
  margin: 20px auto; 
  position: fixed; 
  margin-top: 50px; 
  margin-left: 50px; 
  top: 100px;
  
  /* display: flex;
  justify-content: center;  Center the input horizontally */
}

.search-bar input {
  width: 90%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}


.blog-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
}

@media (max-width: 900px) {
  .blog-list{
    position: relative;
    width: 80%;
    box-sizing: border-box;
  }

  .search-bar {
    position: relative;
    width: 80%;
  }
  .blog-display{
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 100px; 
  }

}



/* #blog {
  position: relative;
  transition: transform 0.5s ease-in-out;
  transform: translateX(100%); 
}

.slide-in {
  transform: translateX(0); 
} */



.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px; 
  color: black; 
}

.dropdown-toggle:hover {
  border-bottom: 2px solid #FFCC02; 
  background-color: transparent;
}

.dropdown-menu {
  background-color: transparent;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
  position: fixed; 
  width: 100px; 
  margin-top: 10px; 
  height: auto; 
  display: flex; 
  text-align: left; 
}

.dropdown-menu a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 13px 
}



.dropdown-toggle.active + .dropdown-menu {
  display: block; /* Show dropdown when active */

}

.dropdown-toggle.active {
  border-bottom: 2px solid #FFCC02; 

}

/* ------------------------- customer reviews ---------------- */ 

.customer-reviews {
  background-color: #f9f9f9; /* Light background for contrast */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 600px; /* Limit width for better readability */
  margin: 20px auto; /* Center the container */
}

.customer-reviews h1 {
  text-align: center; /* Center the heading */
  color: #333; /* Darker color for text */
  margin-bottom: 15px; /* Space below heading */
}

.review {
  font-size: 16px; /* Font size for reviews */
  line-height: 1.5; /* Spacing between lines */
  margin-bottom: 15px; /* Space between reviews */
  color: #555; /* Dark gray color for the text */
}

.review strong {
  color: #007BFF; /* Optional: highlight the reviewer names */
}



/* --------------- fr the process ----------------- */ 

#alignProcessAndArrows{
  display: flex; 
  flex-direction: row;  
  width: 100%; 
  align-items: center;
  justify-content: center;
}

#alignProcessAndArrows > button{
  border-radius: 50%;
  width: 50px; 
  height: 50px; 
  margin: 50px; 
}



/* Container for the steps and description */
.step-manager-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* margin-top: 75px;  */
  /* height: 80vh; */
}

.step-manager-container h1 {
  margin: 0px; 
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white;  
}

#theProcessTitle{
    margin: 0px; 
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white;  
}
 

.process-container{
  background-color: rgb(55, 107, 196);
  height: auto; 
  min-height: 100vh;
}

.process-container h1{
  position: relative; 
  margin: 0px; 
  font-size: 150px;
  color: black; 
  font-weight: bold; 
  font-family: 'Dancing Script', 'Brush Script MT', cursive;
  text-shadow: 6px 3px 2px white; 
}

/* Description and Image styling */
/* .description-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

@media (max-width: 900px) {
  #theProcessTitle {
    font-size: 80px; /* Smaller font size for medium screens */
  }
}

@media (max-width: 500px) {
  #theProcessTitle {
    font-size: 60px; /* Even smaller font size for smaller screens */
  }
}

@media (max-width: 400px) {
  #theProcessTitle {
    font-size: 40px; /* Smallest font size for very small screens */
  }
}



/* Newly added css */ 
/* Default styling for larger screens */
.description-container {
  flex: 2;
  display: flex;
  flex-direction: row; /* Align content horizontally */
  align-items: center;
  justify-content: center;
}

.description-content {
  display: flex;
  flex-direction: row; /* Horizontally align image and text */
  align-items: center;
  justify-content: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.image-container {
  max-width: 50%;
  margin: 10px; 

}

/* Styling for smaller screens */
@media (max-width: 1100px) {
  .description-content {
    flex-direction: column; /* Change to vertical alignment */
    align-items: center;
    justify-content: center;
  }
  
  .image-container img {
    max-width: 80%; /* Reduce image size for smaller screens */
  }

  .text-container h2, 
  .text-container p {
    text-align: center;
    font-size: 1.2rem; /* Adjust font size */
  }
}

/* Styling for very small screens (mobile devices) */
@media (max-width: 700px) {
  .description-container {
    flex-direction: column;
  }

  .image-container img {
    max-width: 100%;
  }

  .text-container h2, 
  .text-container p {
    font-size: 1rem; /* Further adjust font size for smaller devices */
  }
}

/* newly added css finished */ 


.description-content {
  display: flex;
  align-items: center;
}

/* .image-container {
  flex: 1;
  margin-right: 20px;

}

.image-container img {
  width: 500px;
  border-radius: 10px;
  max-height: 400px; 
}

.text-container {
  flex: 2;
  text-align: left;
  padding-left: 20px; 
  border-left: 1px solid black; 
}

.text-container h2 {
  font-size: 30px;
  margin-bottom: 10px;
} */

.text-container p {
  font-size: 25px;
  width: 400px; 
}

/* Steps on the right side */
.steps-container {
  /* flex: 1; */
  margin-left: 50px; 
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 600px) {
  .step-manager-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .steps-container{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .step {
    margin: 20px; 
  }
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

.step-title {
  font-size: 16px;
}

/* Faded out (completed) steps */
.step-completed {
  opacity: 0.4;
}

/* Process controls */
.process-controls {
  position: relative; 
  margin-top: auto;
  text-align: center;
}

/* Process controls */
.process-controls button {
  border-radius: 50%;
  width: 50px; 
  height: 50px; 
}


button {
  background-color: #333;
  color: white;
  border: none;
  /* padding: 10px 20px; */
  cursor: pointer;
  /* margin: 0 10px; */
}

button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

