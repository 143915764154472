.App {
  text-align: center;
}

html, body {
  overflow-x: hidden;
}

body {
  height: 100%; 
  background-color: grey;

}